import React, { useState } from 'react';
import styled from '@emotion/styled';
import { Button, TextField, Alert, Collapse } from '@mui/material';
import Spinner from '../components/Spinner';
import Navbar from '../components/Navbar';
// import { FileUploader } from 'react-drag-drop-files';
import { ethers } from 'ethers';
// import abi from '../lib/abi.js';
// import { ConstructionOutlined, CoPresentOutlined, Launch } from '@mui/icons-material';
// import { wait } from '@testing-library/user-event/dist/utils';
// import { findClosestEnabledDate } from '@mui/x-date-pickers/internals/utils/date-utils';
import lighthouse from '@lighthouse-web3/sdk';
// import { concat } from 'ethers/lib/utils';

const MainContainer = styled.div`
  height: 100vh;
`;
const CenterContainer = styled.div`
  display: block;
  width: 30vw;
  margin: auto;
  padding-top: 10vh;
  text-align: center;
`;

const LoadingSpinner = styled.div`
  text-align: center;
  margin-top: 30vh;
  height: 40vh;
`;
const pricingText = `
As part of our service to you, the one time cost to generate your contract and begin receiving benefits through your NFT sales is of $250 USD which will be charged in its equivalent ETH
`;

function CreateContract() {
  const [name, setName] = useState('');
  const [symbol, setSymbol] = useState('');
  const [costNFT, setCostNFT] = useState('');
  // const [meta, setMeta] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenSuccess, setIsOpenSuccess] = useState(false);
  const [ipfs, setIpfs] = useState([]);
  // const [metaIPFS, setMetaIpfs] = useState([]);
  const [files, setFiles] = useState([]);

  const abi = {
    finance: [
      'function setDefaultSBNContraWpr(address _address) public',
      'function getDefaultSBNContract() public view returns (address)',
      'function initializeSBN(string memory _contractType, string memory _sbnName, address _teamToken, address _teamNFT, uint256 _maxPermitted, uint256 _price) public',
      'function getContractFactoryType() public view returns(address[])',
      'function setAccount(address _accountToPay, address _usdAddress, uint _creationConst) public',
      'function getCreationCost() public view returns(uint)',
      'function createToken(address contractToClone, string name, string symbol) public ',
      'function getCreatedToken() public view returns (address)',
      'function getCreatedSBN() public view returns (address)',
      'function name() public view returns(string)',
    ],
    sefaultSBN: [
      'function name() public view returns (string memory)',
      'function owner() public view returns (address)',
      'function changeOwner(address _newOwner) public',
      'function transferOwnership(address newOwner) public',
      'function purchaseNFT(uint256 _daysInterval, uint _daysCommited, string memory uri) public payable',
      'function setPriceNFT(uint256 value) public',
      'function getPriceNFT() public view returns (uint256)',
      'function teamTokenAddress() public view returns(address)',
      'function acceptOwnership() public',
      'function name() public view returns(string)',
    ],
    teamToken: [
      'function sbn_registerFactory(address _factory) public',
      'function sbnFactory() public view',
      ' function addminterTest(address _minter) public',
      'function owner() public view returns (address)',
      'function acceptOwnership() public',
      'function name() public view returns(string)',
    ],
    simpleBNFT: ['function sbn_registerFactory(address _factory) public', 'function sbnFactory() public view', 'function name() public view returns(string)'],
    usd: [
      'function approve(address spender, uint256 amount) public returns (bool)',
      'function balanceOf(address account) public returns (uint256)',
      'function allowance(address owner, address spender) public returns (uint256)',
      'function transferFrom(address sender, address recipient, uint256 amount) public returns (bool)',
      'function mint(address _account, uint256 _amount) public',
      'function name() public view returns(string)',
    ],
  };

  const nameOnChangeHandler = (newValue) => {
    setName(newValue.target.value);
  };
  const symbolOnChangeHandler = (newValue) => {
    setSymbol(newValue.target.value);
  };

  const priceOnChangeHandler = (newValue) => {
    setCostNFT(newValue.target.value);
  };

  // const fileTypes = ['JSON'];
  // const [file, setFile] = useState(null);

  const progressCallback = (progressData) => {
    let percentageDone = 100 - (progressData?.total / progressData?.uploaded)?.toFixed(2);
    console.log(percentageDone);
  };
  // const uploadFile_old = async (e) => {
  //   console.log(e);
  //   console.log('console files', e.target.files);
  //   console.log('target', e.target);
  //   console.log('file length', e.target.files.length);
  //   const IPFS_data = [];
  //   for (let a = 0; a < e.target.files.length; a++) {
  //     console.log('file:', e.target.files[a]);
  //     const file = e.target.files[a];
  //     const output = await lighthouse.upload([file], '711f26ab.0ec87c2d4a274f29a293015839ba92f5', progressCallback);
  //     console.log('File Status:', output);
  //     console.log('Visit at https://gateway.lighthouse.storage/ipfs/' + output.data.Hash);
  //     IPFS_data.push(output.data.Hash);
  //   }
  //   setIpfs(IPFS_data);
  // };

  const uploadFile = async (e) => {
    console.log(e);
    console.log('console files', e.target.files);
    console.log('target', e.target);
    console.log('file length', e.target.files.length);
    const IPFS_data = [];
    for (let a = 0; a < e.target.files.length; a++) {
      console.log('file:', e.target.files[a]);
      const file = e.target.files[a];
      IPFS_data.push(file);
    }
    setFiles(IPFS_data);
  };

  const uploadfiles = async (e) => {
    const IPFS_data = [];
    console.log('fiels----------', files);
    for (let a = 0; a < files.length; a++) {
      const file = files[a];
      console.log('file----------', file);
      console.log('api key----------', process.env.REACT_APP_LIGHTHOUSE_API_KEY);
      const output = await lighthouse.upload([file], process.env.REACT_APP_LIGHTHOUSE_API_KEY, progressCallback);
      console.log('File Status:', output);
      console.log('Visit at https://gateway.lighthouse.storage/ipfs/' + output.data.Hash);
      IPFS_data.push(output.data.Hash);
    }
    setIpfs(IPFS_data);
  };

  // const fromDecimal = (num) => {
  //   return num.toFixed(6) / 10 ** 6;
  // };

  const toDecimal = (num) => {
    return num.toFixed(6) * 10 ** 6;
  };

  const buttonClickHandler = async () => {
    try {
      // debugger;
      setIsLoading(true);
      await uploadfiles();
      const metadataIPFS = [];
      const IPFS_data = [];

      for (let i = 0; i < ipfs.length; i++) {
        const metadata = {
          name: name.concat('_').concat(i),
          description: name.concat('_').concat(i),
          image: ipfs[i],
          dna: '',
          edition: '',
          date: '',
          attributes: [{ trait_type: '', value: '' }],
        };
        IPFS_data.push(metadata);
        console.log('metadata------------', metadata);
      }

      for (let i = 0; i < IPFS_data.length; i++) {
        const imageData = IPFS_data[i];
        const infoUrl = process.env.NODE_ENV === 'development' ? 'http://localhost:3000/info' : 'https://app.sbn.finance/info';
        console.log('imageData------------', imageData);
        imageData.attributes.push({ trait_type: 'Link', value: infoUrl });
        const tokenURI = { ...imageData, name: `${imageData.name}.${Date.now()}`, attributes: imageData.attributes };
        console.log('tokenURI------------', tokenURI);
        metadataIPFS.push(tokenURI);
      }

      const provider = await new ethers.providers.Web3Provider(window.ethereum);
      const account = await window.ethereum.request({ method: 'eth_requestAccounts' });
      const signer = provider.getSigner();
      const DefaultSBN = await new ethers.Contract(process.env.REACT_APP_DefaultSBN, abi.sefaultSBN, signer);
      const FinanceSBN = await new ethers.Contract(process.env.REACT_APP_FinanceSBN, abi.finance, signer);
      const USD = await new ethers.Contract(process.env.REACT_APP_USDC, abi.usd, signer);
      // const defailtSBNAddress = DefaultSBN.address;
      const cost = parseInt(await FinanceSBN.getCreationCost(), 16);
      console.log('', await (await USD.approve(FinanceSBN.address, toDecimal(cost))).wait());
      const cloneToken = await (await FinanceSBN.createToken(process.env.REACT_APP_SimpleBToken, name, symbol)).wait();
      console.log('clon token creado :', cloneToken);
      const addresClonToken = await FinanceSBN.getCreatedToken();
      console.log('addres clon Token', addresClonToken);
      const defailtSBNClone = await (await FinanceSBN.initializeSBN('NFTAdvance', name, addresClonToken, process.env.REACT_APP_SimpleBNFT, 150000, toDecimal(parseInt(costNFT)))).wait(); //cambiar parametro
      console.log('defailtSBNClone', defailtSBNClone);
      const addresClone = await FinanceSBN.getCreatedSBN();
      console.log('addresClone------------', addresClone.toLowerCase());
      await saveCandidates(account[0], addresClone.toLowerCase(), metadataIPFS, name);

      setIsOpenSuccess(true);
    } catch (error) {
      console.error(error);
      setIsOpen(true);
    } finally {
      setIsLoading(false);
    }
  };

  const deployContracts = async () => {
    // function deployContracts() {
    const provider = await new ethers.providers.Web3Provider(window.ethereum);
    console.log('provider:', provider);
    const account = await window.ethereum.request({ method: 'eth_requestAccounts' });
    console.log('account:', account);
    const signer = provider.getSigner();
    const SimpleBToken = await new ethers.Contract(process.env.REACT_APP_SimpleBToken, abi.teamToken, signer);
    const SimpleBNFT = await new ethers.Contract(process.env.REACT_APP_SimpleBNFT, abi.simpleBNFT, signer);
    const DefaultSBN = await new ethers.Contract(process.env.REACT_APP_DefaultSBN, abi.sefaultSBN, signer);
    const FinanceSBN = await new ethers.Contract(process.env.REACT_APP_FinanceSBN, abi.finance, signer);
    const USD = await new ethers.Contract(process.env.REACT_APP_USDC, abi.usd, signer);
    const minttres = await (await USD.mint('0x3C44CdDdB6a900fa2b585dd299e03d12FA4293BC', toDecimal(50))).wait();
    const mintdos = await (await USD.mint('0x70997970C51812dc3A010C7d01b50e0d17dc79C8', toDecimal(50))).wait();
    console.log('minted');
    console.log('balance of tres', await USD.balanceOf('0x3C44CdDdB6a900fa2b585dd299e03d12FA4293BC'));
    console.log('balance of dos', await USD.balanceOf('0x70997970C51812dc3A010C7d01b50e0d17dc79C8'));
    console.log('FinanceSBN: ', FinanceSBN);
    const defailtSBNAddress = DefaultSBN.address;
    console.log('DefaultSBN.address()', defailtSBNAddress);
    const one = await FinanceSBN.setDefaultSBNContraWpr(defailtSBNAddress);
    const two = await one.wait();
    console.log('getDefaultSBNContract', await FinanceSBN.getDefaultSBNContract());
    console.log('SimpleBToken sbn_registerFactory:', await (await SimpleBToken.sbn_registerFactory(FinanceSBN.address)).wait());
    console.log('SimpleBNFT sbn_registerFactory:', await (await SimpleBNFT.sbn_registerFactory(FinanceSBN.address)).wait());
    const setValues = await (await FinanceSBN.setAccount(process.env.REACT_APP_ALIO_ACCOUNT, process.env.REACT_APP_USDC, toDecimal(1))).wait(); //cambiar el toDecimal(1) por el precio que se quiera cobrar por la creacion del contrato

    // console.log('proceso terminado!');
  };

  const saveCandidates = async (ownerAddress, contractAddress, metadataIPFS, contractName) => {
    try {
      // debugger;
      const baseUrl = process.env.REACT_APP_AWS_API;
      const response = await fetch(`${baseUrl}/candidates`, {
        method: 'POST',

        body: JSON.stringify({
          ownerAddress: ownerAddress,
          contractAddress: contractAddress,
          metadataIPFS: metadataIPFS, // manda el CID generado por ligthhouse
          contractName: contractName,
        }),
      });

      return console.log(response);
    } catch (error) {
      console.error(error);
    }
  };

  return isLoading ? (
    <LoadingSpinner>
      <Spinner></Spinner>
    </LoadingSpinner>
  ) : (
    <div>
      <Navbar />
      <MainContainer>
        <CenterContainer>
          <h1>Create a new contract</h1>

          <Collapse in={isOpenSuccess}>
            <Alert
              severity="success"
              action={
                <Button
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={() => {
                    setIsOpenSuccess(false);
                  }}
                >
                  close
                </Button>
              }
            >
              Success, your contract was made correctly.
            </Alert>
          </Collapse>

          <Collapse in={isOpen}>
            <Alert
              severity="error"
              action={
                <Button
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={() => {
                    setIsOpen(false);
                  }}
                >
                  close
                </Button>
              }
            >
              Oops! Something went wrong, please try again.
            </Alert>
          </Collapse>
          <TextField variant="outlined" color="secondary" label="Enter a Name" margin="normal" fullWidth={true} onChange={nameOnChangeHandler} />
          <TextField variant="outlined" color="secondary" label="Enter a coin Symbol" margin="normal" fullWidth={true} onChange={symbolOnChangeHandler} />
          <TextField variant="outlined" color="secondary" label="Set the Cost" margin="normal" fullWidth={true} type="number" onChange={priceOnChangeHandler} />
          {/* <TextField variant="outlined" color="secondary" label="Test" margin="normal" fullWidth={true}  /> */}
          <h4>Upload metadata file.</h4>
          {/* <FileUploader handleChange={metaOnChangeHandler} name="file" types={fileTypes} /> */}
          {/* <FileUploader handleChange={metaOnChangeHandler} name="file" types={fileTypes} /> */}
          <div className="App">
            <input onChange={(e) => uploadFile(e)} type="file" multiple />
          </div>
          <Button variant="contained" color="primary" size="large" sx={{ margin: '10px 0' }} fullWidth={true} onClick={buttonClickHandler}>
            Submit
          </Button>
          {/* <Button variant="contained" color="primary" size="large" sx={{ margin: '10px 0' }} fullWidth={true} onClick={deployContracts}>
            Deploy contracts
          </Button> */}
          <h5>{pricingText}</h5>
        </CenterContainer>
      </MainContainer>
    </div>
  );
}

export default CreateContract;
