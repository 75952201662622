import { createContext, useContext, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { useLocalStorage } from "./useLocalStorage";
import { ethers } from "ethers";
import MetaMaskOnboarding from "@metamask/onboarding";

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useLocalStorage("user", null);
  const navigate = useNavigate();
  const forwarderOrigin = process.env.NODE_ENV === 'development' ? "http://localhost:3000/login" : "https://app.sbn.finance";
  const onboarding = new MetaMaskOnboarding({ forwarderOrigin });

  const login = async (e) => {
    // const user = await authenticate({ provider: "walletconnect" });
    console.log('user-address:', user.get('ethAddress'));
    navigate("/emailCapture");
  };

  const handleLogin = async() => {
    const baseUrl = process.env.REACT_APP_AWS_API;
    try {
      if(window.ethereum && window.ethereum.isMetaMask){
        const result = await window.ethereum.request({ method: "eth_requestAccounts" });
        console.log(result); 
       
  
        const signer = new ethers.providers.Web3Provider(window.ethereum).getSigner();
        const message = process.env.REACT_APP_SECRET_MESSAGE;
        const signature = await signer.signMessage(message);
        const address = await signer.getAddress();
        
        const response = await fetch(`${baseUrl}/verifysignature`, {
          method: 'POST',
          body: JSON.stringify({
            address: address,
            signature: signature,
          }),
        });

        const verifyresponse = await response.json();
        // console.log('response : ',await response.json());

        return verifyresponse;
      } else {
        console.log("Need to install MetaMask");
        onboarding.StartOnboarding();
      }
    } catch (error) {
      return error;
    }
  }

  const loginMetaMask = async () => {
    //const user = await authenticate();
    //console.log('user-address:', user.get('ethAddress'));
    const response = await handleLogin();
    console.log('response: ',response);
    setUser(response);
    navigate("/emailCapture");
  };

  // call this function to sign out logged in user
  const logoutSession = () => {
    setUser(null)
    localStorage.removeItem('redirUrl');
    navigate("/login", { replace: true });
  };

  const value = useMemo(
    () => ({
      user,
      login,
      loginMetaMask,
      logoutSession,
    }),
    [user]
  );
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
    return useContext(AuthContext);
  };