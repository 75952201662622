import React from 'react';
import styled from '@emotion/styled';

const NftInfo = ({ nftData, open }) => {
  const StyledImg = styled.img`
    margin: 10px;
    border-radius: 10px;
  `;
  const Container = styled.div`
  display: ${open ? 'block' : 'none'};
  `;
  const Grid = styled.div`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
    text-align: center;
  `;

return(
    <Container>
    <Grid>
    <StyledImg src={nftData.img}></StyledImg>
    <div>
    <h4>Name: {nftData.name}</h4>
    <h4>Edition: {nftData.edition}</h4>
    <h4>Address: {nftData.address}</h4>    
    </div>
    </Grid>
    </Container>
)
};

export default NftInfo;