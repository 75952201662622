import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { Button, Select, InputLabel, MenuItem, FormControl } from '@mui/material';
// import _metadata from '../metadata-ipfs/_metadata.json';
import Spinner from '../components/Spinner';
import Navbar from '../components/Navbar';
import { FileUploader } from 'react-drag-drop-files';
// import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
// import { LocalizationProvider } from '@mui/x-date-pickers';
// import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
// import moment from 'moment';
import { ethers } from 'ethers';
import lighthouse from '@lighthouse-web3/sdk';
// import { abi } from '../lib/abi.js';

// const MainContainer = styled.div`
//   display: grid;
//   height: 100vh;
//   grid-template-columns: 1fr 1fr;
// `;
const MainContainer = styled.div`
  height: 100vh;
`;
const LeftContainer = styled.div`
  display: block;
  width: auto;
  color: #353434;
  text-align: center;
`;
const StyledButton = styled.div`
  color: #353434;
  text-align: center;
  border-radius: 10px;
`;
// const RightContianer = styled.div`
//   display: block;
//   width: auto;
//   height: 80vh;
//   margin: 10vh 0;
//   border-left: solid #908f8f 3px;
//   text-align: center;
//   padding: 10%;
// `;
const TextContainer = styled.div`
  margin-top: 10vh;
`;
// const InputContainer = styled.div`
//   display: grid;
//   grid-template-columns: repeat(2, 1fr);
//   gap: 10px;
// `;
// const NftContainer = styled.div`
//   margin: 10px 0;
//   height: 80vh;
//   overflow-y: scroll;
// `;

// const StyledImg = styled.img`
//   margin: 10px;
//   border-radius: 10px;
//   &:hover {
//     cursor: pointer;
//   }
// `;

const LoadingSpinner = styled.div`
  text-align: center;
  margin-top: 30vh;
  height: 40vh;
`;

function LoadImages() {
  const [contract, setContract] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const effectCalled = useRef(false);
  // const [images, setImages] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [meta, setMeta] = useState('');
  const [dataContracts, setDataContracts] = useState([]);
  const [ipfs, setIpfs] = useState([]);
  const [files, setFiles] = useState([]);
  // const [name, setName] = useState('');

  const abi = {
    finance: [
      'function setDefaultSBNContraWpr(address _address) public',
      'function getDefaultSBNContract() public view returns (address)',
      'function initializeSBN(string memory _contractType, string memory _sbnName, address _teamToken, address _teamNFT, uint256 _maxPermitted, uint256 _price) public',
      'function getContractFactoryType() public view returns(address[])',
      'function setAccount(address _accountToPay, address _usdAddress, uint _creationConst) public',
    ],
    defaultSBN: [
      'function name() public view returns (string memory)',
      'function owner() public view returns (address)',
      'function changeOwner(address _newOwner) public',
      'function purchaseNFT(uint256 _daysInterval, uint _daysCommited, string memory uri) public payable',
      'function setPriceNFT(uint256 value) public',
      'function getPriceNFT() public view returns (uint256)',
    ],
    teamToken: ['function sbn_registerFactory(address _factory) public onlyOwner', 'function sbnFactory() public view'],
    simpleBNFT: ['function sbn_registerFactory(address _factory) public onlyOwner', 'function sbnFactory() public view'],
    usd: [
      'function approve(address spender, uint256 amount) public returns (bool)',
      'function balanceOf(address account) public returns (uint256)',
      'function allowance(address owner, address spender) public returns (uint256)',
      'function transferFrom(address sender, address recipient, uint256 amount) public returns (bool)',
      'function mint(address _account, uint256 _amount) public',
    ],
  };

  const fetchContracts = async () => {
    try {
      const provider = await new ethers.providers.Web3Provider(window.ethereum);
      const account = await window.ethereum.request({ method: 'eth_requestAccounts' });

      const signer = provider.getSigner();
      const FinanceSBN = await new ethers.Contract(process.env.REACT_APP_FinanceSBN, abi.finance, signer);
      const contracts = await FinanceSBN.getContractFactoryType();
      const totalOfContracts = contracts.length;

      const getNames = contracts.map(async (address) => {
        const DefaultSBNC = await new ethers.Contract(address, abi.defaultSBN, signer);
        const name = await DefaultSBNC.name();
        return { name: name, contractAddress: address };
      });

      const dataContracts = await Promise.all(getNames);
      setDataContracts(dataContracts);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    const fetchCall = async () => {
      try {
        setIsLoading(true);
        await fetchContracts();
      } catch (error) {
        console.error(error);
        setIsOpen(true);
      } finally {
        setIsLoading(false);
      }
    };
    if (!effectCalled.current) {
      fetchCall();
      effectCalled.current = true;
    }
  }, []);

  useEffect(() => {
    const fetchCall = async () => {
      try {
        setIsLoading(true);
        const baseUrl = process.env.REACT_APP_AWS_API;
        if (contract) {
          // const response = await fetch(`${baseUrl}/candidates/contract/${contract.toLowerCase()}`);
          // const candidatesData = await response.json();
          // console.log('candidatesData---- :', candidatesData[0]);
          // setImages(candidatesData);
        }
      } catch (error) {
        console.error(error);
        setIsOpen(true);
      } finally {
        setIsLoading(false);
      }
    };

    fetchCall();
  }, [contract]);

  function makeDays() {
    const days = [];
    for (var i = 60; i < 90; i++) {
      const obj = {};
      obj['value'] = (i + 1) * 86400; //86400 = seconds in a day
      obj['id'] = i + 1;
      obj['text'] = (i + 1).toString() + ' days';
      days.push(obj);
    }
    return days;
  }

  const handleContract = (e) => {
    setContract(e.target.value);
    console.log('contract-selected:', e.target.value);
  };

  const days = makeDays();
  const fileTypes = ['JSON'];
  const [file, setFile] = useState(null);

  const metaOnChangeHandler = (newValue) => {
    try {
      setFile(newValue);
      console.log(newValue);
      const file_to_read = newValue;
      const fileread = new FileReader();
      fileread.onload = function (e) {
        const content = e.target.result;
        const intern = JSON.parse(content);
        intern.map((key) => {
          if (
            !key.hasOwnProperty('name') ||
            typeof key.name !== 'string' ||
            !key.hasOwnProperty('description') ||
            typeof key.description !== 'string' ||
            !key.hasOwnProperty('image') ||
            typeof key.image !== 'string' ||
            !key.hasOwnProperty('dna') ||
            typeof key.dna !== 'string' ||
            !key.hasOwnProperty('edition') ||
            typeof key.edition !== 'number' ||
            !key.hasOwnProperty('date') ||
            typeof key.date !== 'number' ||
            !key.attributes[0].hasOwnProperty('trait_type') ||
            typeof key.attributes[0].trait_type !== 'string' ||
            !key.attributes[0].hasOwnProperty('value') ||
            typeof key.attributes[0].value !== 'string' /* ||
            Object.keys(key).length === 7 */
          ) {
            setIsOpen(true);
            setFile(null);
            throw `The schema of the .JSON file is not valid: ${JSON.stringify(key)}`;
          }
        });
        setMeta(intern);
      };
      fileread.readAsText(file_to_read);
    } catch (error) {
      console.log(error);
      setIsOpen(true);
    }
  };
  const saveCandidates = async (ownerAddress, contractAddress, metadataIPFS, contractName) => {
    try {
      // debugger;
      const baseUrl = process.env.REACT_APP_AWS_API;
      const response = await fetch(`${baseUrl}/candidates`, {
        method: 'POST',

        body: JSON.stringify({
          ownerAddress: ownerAddress,
          contractAddress: contractAddress,
          metadataIPFS: metadataIPFS, // manda el CID generado por ligthhouse
          contractName: contractName,
        }),
      });

      return console.log(response);
    } catch (error) {
      console.error(error);
    }
  };

  const uploadFile = async (e) => {
    console.log(e);
    console.log('console files', e.target.files);
    console.log('target', e.target);
    console.log('file length', e.target.files.length);
    const IPFS_data = [];
    for (let a = 0; a < e.target.files.length; a++) {
      console.log('file:', e.target.files[a]);
      const file = e.target.files[a];
      IPFS_data.push(file);
    }
    setFiles(IPFS_data);
  };
  const progressCallback = (progressData) => {
    let percentageDone = 100 - (progressData?.total / progressData?.uploaded)?.toFixed(2);
    console.log(percentageDone);
  };

  const uploadfiles = async (e) => {
    const IPFS_data = [];
    console.log('fiels----------', files);
    for (let a = 0; a < files.length; a++) {
      const file = files[a];
      console.log('file----------', file);
      console.log('api key----------', process.env.REACT_APP_LIGHTHOUSE_API_KEY);
      const output = await lighthouse.upload([file], process.env.REACT_APP_LIGHTHOUSE_API_KEY, progressCallback);
      console.log('File Status:', output);
      console.log('Visit at https://gateway.lighthouse.storage/ipfs/' + output.data.Hash);
      IPFS_data.push(output.data.Hash);
    }
    setIpfs(IPFS_data);
  };

  const buttonClickHandler = async () => {
    setIsLoading(true);
    // console.log('entro a la funcion');
    const provider = await new ethers.providers.Web3Provider(window.ethereum);
    const account = await window.ethereum.request({ method: 'eth_requestAccounts' });
    const signer = provider.getSigner();
    const defaultSBN = await new ethers.Contract(contract, abi.defaultSBN, signer);
    const contractName = await defaultSBN.name();
    await uploadfiles();
    const metadataIPFS = [];
    const IPFS_data = [];

    for (let i = 0; i < ipfs.length; i++) {
      const metadata = {
        name: contractName.concat('_').concat(i),
        description: contractName.concat('_').concat(i),
        image: ipfs[i],
        dna: '',
        edition: '',
        date: '',
        attributes: [{ trait_type: '', value: '' }],
      };
      IPFS_data.push(metadata);
      console.log('metadata------------', metadata);
    }

    for (let i = 0; i < IPFS_data.length; i++) {
      const imageData = IPFS_data[i];
      const infoUrl = process.env.NODE_ENV === 'development' ? 'http://localhost:3000/info' : 'https://app.sbn.finance/info';
      imageData.attributes.push({ trait_type: 'Link', value: infoUrl });
      const tokenURI = { ...imageData, name: `${imageData.name}.${Date.now()}`, attributes: imageData.attributes };
      // console.log('tokenURI------------', tokenURI);
      metadataIPFS.push(tokenURI);
    }

    // console.log('metadataIPFS', metadataIPFS);
    // const provider = await new ethers.providers.Web3Provider(window.ethereum);
    // const account = await window.ethereum.request({ method: 'eth_requestAccounts' });
    // const signer = provider.getSigner();
    // const defaultSBN = await new ethers.Contract(contract, abi.defaultSBN, signer);
    // const contractName = await defaultSBN.name();
    await saveCandidates(account[0], contract.toLowerCase(), metadataIPFS, contractName);
    // alert('Candidates saved successfully');
    setIsLoading(false);
  };

  return isLoading ? (
    <LoadingSpinner>
      <Spinner>{/*hacer bonito el spinner y no dejar de renderear lo otro */}</Spinner>
    </LoadingSpinner>
  ) : (
    <div>
      <Navbar />
      <MainContainer id="page-wrap">
        <LeftContainer>
          <TextContainer>
            <h2>Gallery / NFT Browser</h2>
            <FormControl sx={{ m: 1, minWidth: 350 }}>
              <div style={{ gridArea: '1 / 1 / 2 / 4' }}>
                <FormControl fullWidth>
                  <InputLabel id="Contract-label" color="secondary">
                    Contract
                  </InputLabel>
                  <Select labelId="contract-label" id="contract" value={contract} label="Contract" onChange={handleContract}>
                    {dataContracts.map((item, index) => (
                      <MenuItem key={index} value={item.contractAddress}>
                        {item.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
              <h4>Upload metadata file.</h4>
              {/* <FileUploader handleChange={metaOnChangeHandler} name="file" types={fileTypes} />
              <p>{file ? `File name: ${file.name}` : 'no files uploaded yet'}</p> */}
              <div className="App">
                <input onChange={(e) => uploadFile(e)} type="file" multiple />
              </div>
              <Button variant="contained" color="primary" size="large" sx={{ margin: '10px 0' }} fullWidth={true} onClick={buttonClickHandler}>
                Submit
              </Button>
            </FormControl>
            {/* <NftContainer>
              <div>
                {images.map((item) => (
                  // <img key={item.id} style={{ marginRight: '10px', marginBottom: '10px' }} src={item.url} alt={item.id} />
                  <StyledImg key={item.imageName} src={item.imageURL} alt={item.imageName} onClick={() => nftClickHandler(item)} />
                ))}
              </div>
            </NftContainer> */}
          </TextContainer>
        </LeftContainer>
      </MainContainer>
    </div>
  );
}
export default LoadImages;
