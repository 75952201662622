import { React, useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { Button, TextField } from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';
import { useConnection } from '../store';
import { useAuth } from '../hooks/useAuth';
import Spinner from '../components/Spinner';

const MainContainer = styled.div`
  height: 100vh;
`;
const CenterContainer = styled.div`
  display: block;
  width: 30vw;
  margin: auto;
  padding-top: 10vh;
  text-align: center;
`;

const LoadingSpinner = styled.div`
  text-align: center;
  margin-top: 30vh;
  height: 40vh;
`;
function EmailCapture() {
  const { user } = useAuth();
  const email = useConnection((state) => state.email);
  const setEmail = useConnection((state) => state.setEmail);
  const effectCalled = useRef(false);
  const navigate = useNavigate();
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(true);

  const sumitClickHandler = async (e) => {
    try {
      const baseUrl = process.env.REACT_APP_AWS_API;
      const address = user.address.toLowerCase();

      const response = await fetch(`${baseUrl}/users`, {
        method: 'POST',
        body: JSON.stringify({
          address: address,
          email: email,
        }),
      });

      if (response.status === 200) {
        navigate('/nftGallery' + location.search);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const emailOnChangeHandler = (newValue) => {
    setEmail(newValue.target.value);
  };

  useEffect(() => {
    const fetchCall = async () => {
      const baseUrl = process.env.REACT_APP_AWS_API;
      console.log('address: ', user);
      const address = user.address.toLowerCase();

      const response = await fetch(`${baseUrl}/users/${address}`, { method: 'GET' });

      const { email } = await response.json();

      if (email) {
        console.log('email redirUrl is ', localStorage.getItem('redirUrl'));
        if (localStorage.getItem('redirUrl')) {
          const url = localStorage.getItem('redirUrl');
          localStorage.removeItem('redirUrl');
          navigate(url);
        } else {
          navigate('/nftGallery' + location.search);
        }
      }
      setIsLoading(false);
    };

    if (!effectCalled.current) {
      effectCalled.current = true;
      fetchCall();
    }
  }, []);

  return isLoading ? (
    <LoadingSpinner>
      <Spinner></Spinner>
    </LoadingSpinner>
  ) : (
    <MainContainer>
      <CenterContainer>
        <h1> One more thing and we'll be ready</h1>
        <h3>Enter your email address</h3>
        <TextField id="email" color="secondary" variant="outlined" type="email" onChange={emailOnChangeHandler}>
          Enter Your Email
        </TextField>
        <p>We’ll only use your email address to remind you when a contract is about to expire.</p>
        <Button variant="contained" color="primary" size="large" onClick={sumitClickHandler}>
          Submit
        </Button>
      </CenterContainer>
    </MainContainer>
  );
}
export default EmailCapture;
