import React from 'react';

const Spinner = ({ customStyles }) => {
  const defaultStyles = {
    border: '10px solid #f3f3f3',
    borderTop: '10px solid #3498db',
    width: '200px',
    height: '200px',
    borderRadius: '50%',
    animation: 'spin 1s ease-in-out infinite',
    display: 'inline-block',
    margin: '0px auto',
  };

  return <div style={{ ...defaultStyles, ...customStyles }}></div>;
};

export default Spinner;
