import { Navigate, Outlet } from "react-router-dom";
import { useAuth } from "../hooks/useAuth";

export const ProtectedLogin = () => {
  const { user } = useAuth();
  if (user) {
    // user is authenticated
    localStorage.setItem('enter',true);
    console.log('protected login redirUrl is ', localStorage.getItem('redirUrl'))
    return <Navigate to="/EmailCapture" />;
  }
  return <Outlet />;
};