import React from 'react';
import { slide as Menu } from 'react-burger-menu';
import styled from '@emotion/styled';
import { Link } from 'react-router-dom';
import { useAuth } from '../hooks/useAuth';
import { MenuItem as Logout } from '@mui/material';

const InternalMenu = styled.div`
  padding: 5vh 0;
  display: inline-block;
`;
const MenuItem = styled(Link)`
  display: block;
  padding: 3vh 0;
  color: #ffffff;
  font-size: 16px;
  text-decoration: none;
  :hover {
    color: #908f8f;
  }
`;
const styles = {
  bmBurgerButton: {
    position: 'fixed',
    width: '30px',
    height: '24px',
    left: '30px',
    top: '30px',
  },
  bmBurgerBars: {
    background: '#373a47',
  },
  bmBurgerBarsHover: {
    background: '#a90000',
  },
  bmCrossButton: {
    height: '24px',
    width: '24px',
  },
  bmCross: {
    background: '#bdc3c7',
  },
  bmMenuWrap: {
    position: 'fixed',
    height: '100%',
  },
  bmMenu: {
    background: 'linear-gradient(180deg, #20114a 0%, rgba(97, 190, 230, 0.7) 100%)',
    padding: '2.5em 1.5em 0',
    fontSize: '1.15em',
  },
  bmMorphShape: {
    fill: '#373a47',
  },
  bmItemList: {
    color: '#b8b7ad',
    padding: '0.8em',
  },
  bmItem: {
    display: 'inline-block',
  },
  bmOverlay: {
    background: 'rgba(0, 0, 0, 0.3)',
  },
};

function Navbar() {
  const { logoutSession } = useAuth();

  const logoutHandler = async (e) => {
    e.preventDefault();
    logoutSession();
  };

  return (
    <Menu styles={styles}>
      <InternalMenu>
        <MenuItem to="/nftGallery">Create NFT</MenuItem>
        <MenuItem to="/sbPage">Sell Buyback NFT</MenuItem>
        <MenuItem to="/createContract">Create Contract</MenuItem>
        <MenuItem to="/LoadImages">Load Images</MenuItem>
        <br />
        <MenuItem to="/info">INFO</MenuItem>
        <MenuItem to="/faq">FAQ</MenuItem>
        <br />
        <MenuItem to="/login" onClick={logoutHandler}>
          Logout
        </MenuItem>
      </InternalMenu>
    </Menu>
  );
}
export default Navbar;
