import create from 'zustand';
import { ethers } from 'ethers';

const useConnection = create((set) => ({
  provider: null,
  accounts: null,
  connected: false,
  email: '',
  setProvider: (value) => set(() => ({ provider: value })),
  setAccounts: (value) => set(() => ({ accounts: value })),
  setConnected: (value) => set(() => ({ connected: value })),
  setEmail: (value) => set(() => ({ email: value })),
  connect: async () => {
    const provider = await new ethers.providers.Web3Provider(window.ethereum);
    const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
    console.log('connected...');

    set(() => ({ connected: true, provider: provider, accounts: accounts }));
  },
}));

export default useConnection;
