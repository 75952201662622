import React, { useState, useRef, useEffect } from 'react';
import styled from '@emotion/styled';
import { useSearchParams } from 'react-router-dom';
import Spinner from '../components/Spinner';
import { Button, Select, InputLabel, MenuItem, FormControl, TextField, Collapse, Alert } from '@mui/material';
import Navbar from '../components/Navbar';
import NftInfo from '../components/NftInfo';
import { ethers } from 'ethers';

const MainContainer = styled.div`
  height: 100vh;
`;
const CenterContainer = styled.div`
  display: block;
  width: 40vw;
  margin: auto;
  padding-top: 10vh;
  text-align: center;
`;

const LoadingSpinner = styled.div`
  text-align: center;
  margin-top: 30vh;
  height: 40vh;
`;
const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(4, 1fr);
  gap: 10px;
  text-align: center;
`;
const NftContainer = styled.div`
  margin: 10px 0;
  height: 80vh;
  overflow-y: scroll;
`;
const StyledImg = styled.img`
  margin: 10px;
  border-radius: 10px;
  &:hover {
    cursor: pointer;
  }
`;

function SBPage() {
  // const { user } = {};

  const [contract, setContract] = useState('');
  const [token, setToken] = useState('');
  const [tokenNFT, setTokenNFT] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const effectCalled = useRef(false);
  // const [searchParams] = useSearchParams();
  const [open, setOpen] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [previewInfo, setPreviewInfo] = useState({});
  const [isOpenSuccess, setIsOpenSuccess] = useState(false);
  const [dataContracts, setDataContracts] = useState([]);
  const [metadataNFT, setMetadataNFT] = useState([]);
  // const storageAddress = process.env.REACT_APP_STORAGE;

  const abi = {
    finance: [
      // 'function name() public view',
      'function setDefaultSBNContraWpr(address _address) public',
      'function getDefaultSBNContract() public view returns (address)',
      'function initializeSBN(string memory _contractType, string memory _sbnName, address _teamToken, address _teamNFT, uint256 _maxPermitted, uint256 _price) public',
      'function getContractFactoryType() public view returns(address[])',
      'function setAccount(address _accountToPay, address _usdAddress, uint _creationConst) public',
      'function getCreationCost() public view returns(uint)',
    ],
    defaultSBN: [
      'function name() public view returns (string memory)',
      'function owner() public view returns (address)',
      'function changeOwner(address _newOwner) public',
      'function purchaseNFT(uint256 _daysInterval, uint _daysCommited, string memory uri) public payable',
      'function setPriceNFT(uint256 value) public',
      'function getPriceNFT() public view returns (uint256)',
      'function sellBackNFT(uint256 _nftID) public ',
      'function setValidationSellBack(bool value) public',
      'function teamNFTAddress() public view returns(address)',
    ],
    teamToken: [
      // 'function name() public view',
      'function sbn_registerFactory(address _factory) public onlyOwner',
      'function sbnFactory() public view',
    ],
    simpleBNFT: [
      // 'function name() public view',
      'function sbn_registerFactory(address _factory) public',
      'function sbnFactory() public view',
      'function getMetadataByAddress(address cont) public view returns (string[] memory)',
      'function getTokens(address cont) public view returns (uint256[] memory)',
      'function getMetaByToken(address cont, uint index) public view returns (string memory)',
      'function tokenURI(uint256 tokenId)public view returns (string memory)',
      'function approve(address spender, uint256 amount) public ',
      'function getApproved(uint256 token) public view returns(address) ',
      'function getMetadata(uint token) public view returns (string)',
    ],
  };

  useEffect(() => {
    const fetchCall = async () => {
      try {
        setIsLoading(true);
        await fetchContracts();
      } catch (error) {
        console.error(error);
        setIsOpen(true);
      } finally {
        setIsLoading(false);
      }
    };

    if (!effectCalled.current) {
      fetchCall();
      effectCalled.current = true;
    }
  }, []);

  const fetchContracts = async () => {
    try {
      const provider = await new ethers.providers.Web3Provider(window.ethereum);
      const account = await window.ethereum.request({ method: 'eth_requestAccounts' });

      const signer = provider.getSigner();
      const FinanceSBN = await new ethers.Contract(process.env.REACT_APP_FinanceSBN, abi.finance, signer);
      const contracts = await FinanceSBN.getContractFactoryType();
      // const totalOfContracts = contracts.length;

      const getNames = contracts.map(async (address) => {
        const DefaultSBNC = await new ethers.Contract(address, abi.defaultSBN, signer);
        const name = await DefaultSBNC.name();
        return { name: name, contractAddress: address };
      });

      const dataContracts = await Promise.all(getNames);
      setDataContracts(dataContracts);
    } catch (error) {
      console.error(error);
    }
  };

  const tokenOnChangeHandler = async (newValue) => {
    setToken(newValue.target.value);

    if (newValue.target.value === '') {
      setOpen(false);
    } else {
      try {
        setIsLoading(true);
        const provider = await new ethers.providers.Web3Provider(window.ethereum);
        const account = await window.ethereum.request({ method: 'eth_requestAccounts' });
        const signer = provider.getSigner();
        const defaulSBN = new ethers.Contract(contract, abi.defaultSBN, signer);
        const nft = new ethers.Contract(defaulSBN.teamNFTAddress(), abi.simpleBNFT, signer);
        const data = {};
        const tokensResponse = await nft.getTokens(contract);

        const tokens = tokensResponse.map((token) => Number(token));
        const realtoken = tokens[newValue.target.value];

        setTokenNFT(realtoken);
        // console.log('token selected', realtoken);
        setTokenNFT(realtoken);
        const response = await nft.connect(signer).getMetadata(realtoken);
        // console.log('metadata', response);

        // const encode = response.split(',')[1];
        // console.log('encode', encode);
        // const formattedEncode = JSON.parse(atob(encode));
        const formattedEncode = JSON.parse(response);
        data.img = formattedEncode.image.replace('ipfs://', 'https://cloudflare-ipfs.com/ipfs/');
        data.name = formattedEncode.name;
        data.address = contract.toString();
        data.edition = formattedEncode.edition;
        //TODO: get creation date
        data.createdAt = 'WIP';
        setPreviewInfo(data);
        setOpen(true);
      } catch (e) {
        console.log("Token doesn't exist");
        setOpen(false);
      } finally {
        setIsLoading(false);
      }
    }
  };

  const yesClickHandler = async () => {
    try {
      setIsLoading(true);
      const provider = await new ethers.providers.Web3Provider(window.ethereum);
      const account = await window.ethereum.request({ method: 'eth_requestAccounts' });
      const signer = provider.getSigner();
      const defaulSBN = new ethers.Contract(contract, abi.defaultSBN, signer);
      const nft = new ethers.Contract(defaulSBN.teamNFTAddress(), abi.simpleBNFT, signer);
      console.log('', await (await nft.approve(contract, tokenNFT)).wait());
      console.log('', await nft.getApproved(tokenNFT));
      await (await defaulSBN.setValidationSellBack(true)).wait(); //change the variable to false to bypass the las payment validation
      await (await defaulSBN.sellBackNFT(tokenNFT)).wait();
      cleanDetail();
      await loadMetadata();

      setIsOpenSuccess(true);
    } catch (error) {
      console.error(error);
      setIsOpen(true);
    } finally {
      setIsLoading(false);
    }
  };
  const cleanDetail = () => {
    const data = {};
    setPreviewInfo(data);
    setOpen(false);
    setTokenNFT('');
  };
  const noClickHandler = () => {
    cleanDetail();
  };

  const loadMetadata = async () => {
    if (contract) {
      const provider = await new ethers.providers.Web3Provider(window.ethereum);
      const account = await window.ethereum.request({ method: 'eth_requestAccounts' });
      const signer = provider.getSigner();
      const defaulSBN = new ethers.Contract(contract, abi.defaultSBN, signer);
      // console.log('addres clon: ', await defaulSBN.teamNFTAddress());
      const nft = new ethers.Contract(await defaulSBN.teamNFTAddress(), abi.simpleBNFT, signer);
      const tokensResponse = await nft.getTokens(contract);
      const tokens = tokensResponse.map((token) => Number(token));
      //   const encode = response.split(',')[1];
      // console.log('encode', encode);
      // const formattedEncode = JSON.parse(atob(encode));
      const metadata = (await nft.getMetadataByAddress(contract)).map((item) => JSON.parse(item));
      const imageFileName = metadata.map((item) => item.image.split('/').slice(-1));
      const baseUrl = process.env.REACT_APP_AWS_API;
      const response = await fetch(`${baseUrl}/candidates/urls`, {
        method: 'POST',
        body: JSON.stringify({
          address: contract.toLowerCase(),
          images: imageFileName,
        }),
      });
      const urls = (await response.json()).urls;
      const metadataNFT = tokens.map((item, index) => ({ token: item, imageName: imageFileName[index], imageURL: urls[index], ...metadata[index] }));
      setMetadataNFT(metadataNFT);
    }
  };

  useEffect(() => {
    const fetchCall = async () => {
      try {
        setIsLoading(true);
        const baseUrl = process.env.REACT_APP_AWS_API;
        await loadMetadata();
      } catch (error) {
        console.error(error);
        setIsOpen(true);
      } finally {
        setIsLoading(false);
      }
    };

    fetchCall();
  }, [contract]);

  const selectImage = (item) => () => {
    setTokenNFT(item.token);
    const data = {};
    data.img = item.imageURL;
    data.name = item.name;
    data.address = contract.toString();
    data.edition = item.edition;
    //TODO: get creation date
    data.createdAt = 'WIP';
    setPreviewInfo(data);
    setOpen(true);
  };
  const handleContract = (e) => {
    setContract(e.target.value);
    setToken('');
    setTokenNFT('');
    setOpen(false);
  };

  return isLoading ? (
    <LoadingSpinner>
      <Spinner></Spinner>
    </LoadingSpinner>
  ) : (
    <div>
      <Navbar />
      <MainContainer>
        <CenterContainer>
          <h1>Last Payment</h1>
          <h3>Sell back NFT?</h3>

          <Collapse in={isOpenSuccess}>
            <Alert
              severity="success"
              action={
                <Button
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={() => {
                    setIsOpenSuccess(false);
                  }}
                >
                  close
                </Button>
              }
            >
              Success, you sold back the NFT correctly.
            </Alert>
          </Collapse>

          <Collapse in={isOpen}>
            <Alert
              severity="error"
              action={
                <Button
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={() => {
                    setIsOpen(false);
                  }}
                >
                  close
                </Button>
              }
            >
              Oops! Something went wrong, please try again.
            </Alert>
          </Collapse>
          <Grid>
            <div style={{ gridArea: '1 / 1 / 2 / 4' }}>
              <FormControl fullWidth>
                <InputLabel id="Contract-label" color="secondary">
                  Contract
                </InputLabel>
                <Select labelId="contract-label" id="contract" value={contract} label="Contract" onChange={handleContract}>
                  {dataContracts.map((item, index) => (
                    <MenuItem key={index} value={item.contractAddress}>
                      {item.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
            <div style={{ gridArea: '1 / 4 / 2 / 5' }}>
              <TextField variant="outlined" color="secondary" label="Token" fullWidth={true} onChange={tokenOnChangeHandler} value={token} />
            </div>
            <div style={{ gridArea: '2 / 1 / 3 / 3' }}>
              <Button variant="contained" color="primary" size="large" fullWidth={true} onClick={yesClickHandler}>
                Yes
              </Button>
            </div>
            <div style={{ gridArea: '2 / 3 / 3 / 5' }}>
              <Button variant="contained" color="primary" size="large" fullWidth={true} onClick={noClickHandler}>
                No
              </Button>
            </div>
          </Grid>
          <div>
            <NftInfo nftData={previewInfo} open={open} />
          </div>
          <div>
            <h3>Your NFT</h3>
          </div>
          <div>
            <NftContainer>
              <div>
                {metadataNFT.map((item) => (
                  // <img key={item.id} style={{ marginRight: '10px', marginBottom: '10px' }} src={item.url} alt={item.id} />
                  <StyledImg key={item.imageName} src={item.imageURL} alt={item.imageName} onClick={selectImage(item)} />
                ))}
              </div>
            </NftContainer>
          </div>
        </CenterContainer>
      </MainContainer>
    </div>
  );
}

export default SBPage;
