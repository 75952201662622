import React from 'react';
import styled from '@emotion/styled';
import Navbar from '../components/Navbar';
import Highlight from 'react-highlight';
import '../../node_modules/highlight.js/styles/nord.css';
import { Accordion, AccordionSummary, AccordionDetails, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CodeIcon from '@mui/icons-material/Code';
import EmojiObjectsIcon from '@mui/icons-material/EmojiObjects';
import { CreateNFT, SellBackNFT, CreateContract } from '../images/index';

const MainContainer = styled.div`
  height: 100vh;
`;
const CenterContainer = styled.div`
  display: block;
  width: 55vw;
  margin: auto;
  padding-top: 10vh;
`;

const EmbedCode = styled.div`
  text-align: left;
  font-size: 12px;
`;

const accordionStyles = {
    marginTop: '1rem',
    borderRadius: '5px',
}

const imageStyles = {
    maxWidth: '100%',
    height: 'auto',
    padding: '0',
    margin: '0',
    borderRadius: '5px',
}

const centerStyles = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
}

function FAQ() {

    const contractCode = `
    //SPDX-License-Identifier: MIT
    pragma solidity ^0.8.9;
    
    import 'hardhat/console.sol';
    import '@openzeppelin/contracts/token/ERC721/extensions/ERC721URIStorage.sol';
    import '@openzeppelin/contracts/access/Ownable.sol';
    import 'base64-sol/base64.sol';
    import './NFTFactory.sol';
    import './RBT.sol';
    
    contract NFT is ERC721URIStorage, Ownable {
        uint256 public tokenCounter;
        
        event CreatedNFT(address owner, uint256 indexed tokenId);

        constructor(string memory _name, string memory _symbol) ERC721(_name, _symbol) {
            tokenCounter = 0;
        }
        
        function create(string memory _tokenURI) public payable {
            address nftOwner = msg.sender;
            uint256 tokenId = tokenCounter;

            _mint(nftOwner, tokenId);
            string memory tokenURI = _creterTokenURI(_tokenURI);
            _setTokenURI(tokenId, tokenURI);        
            transferFrom(nftOwner, tx.origin, tokenId);
        
            tokenCounter = tokenCounter + 1;
        
            if (owner() != msg.sender) {
                (bool success, ) = owner().call{value: msg.value}('');
                require(success, 'call failed');
            }
        
            emit CreatedNFT(nftOwner, tokenId);
        }
        
        function _creterTokenURI(string memory _tokenURI) private pure returns (string memory tokenURI) {
            string memory jsonbase64 = Base64.encode(bytes(abi.encodePacked(_tokenURI)));
            string memory base = 'data:application/json;base64,';
            tokenURI = string(abi.encodePacked(base, jsonbase64));
        }
    }
    `;

    const schema = `
    [
        {
            "name": "name-test",
            "description": "sbn-test",
            "image": "ipfs link test",
            "dna": "dna test",
            "edition": 0000,
            "date": 0000,
            "attributes": [
                {
                    "trait_type": "trait type test",
                    "value": "value test"
                }
            ]
        }
    ]
    `;

    return (
        <div>
            <Navbar />
            <MainContainer>
                <CenterContainer>
                    <h1 style={{ textAlign: 'center' }}>FAQ</h1>
                    <Accordion sx={{ borderRadius: '5px' }}>
                        <AccordionSummary expandIcon={ <ExpandMoreIcon /> }>
                            <Typography sx={{ fontWeight: 'bold' }}>How to create a contract and how it should look like</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography sx={{ textAlign: 'justify' }}>
                                In the module called <strong>Create Contract</strong> you have the possibility to create 
                                a new contract. In this section a form will be displayed where you have to 
                                provide the name and symbol of the contract. You also need to upload the 
                                metadata file (<strong>.JSON file</strong>).
                            </Typography>
                            <Accordion style={ accordionStyles }>
                                <AccordionSummary expandIcon={ <EmojiObjectsIcon /> }>
                                    <Typography>Click here to see an example</Typography>
                                </AccordionSummary>
                                <AccordionDetails style={ centerStyles }>
                                    <Typography>
                                        <img style={ imageStyles } src={ CreateContract } alt="Create Contract example" />
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>
                            <Typography sx={{ textAlign: 'justify', marginTop: '1em' }}>
                                The respective file must respect a certain <strong>schema</strong> to be valid.
                            </Typography>
                            <Accordion style={ accordionStyles }>
                                <AccordionSummary expandIcon={<CodeIcon />}>
                                    <Typography>Click here to see the schema example</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography>
                                        <Highlight className="json">
                                            <EmbedCode>
                                                { schema }
                                            </EmbedCode>
                                        </Highlight>
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>
                            <Typography sx={{ textAlign: 'justify', marginTop: '1em' }}>
                                The contract code must have the following <strong>structure.</strong> However, extra statements can be added.
                            </Typography>
                            <Accordion style={ accordionStyles }>
                                <AccordionSummary expandIcon={ <CodeIcon /> }>
                                    <Typography>Click here to see the code example</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography>
                                        <Highlight className="javascript">
                                            <EmbedCode>
                                                { contractCode }
                                            </EmbedCode>
                                        </Highlight>
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>
                            <Typography sx={{ textAlign: 'justify', marginTop: '1em' }}>
                                <p>The contract follows the rules and structure of 
                                <strong> EIP-721: Non-Fungible Token Standard</strong>. It is a standard interface 
                                for non-fungible tokens, also known as deeds.</p>
                                <p style={{ marginBottom: '0' }}>This standard allows for the implementation of a standard 
                                API for NFTs within smart contracts. This standard provides basic functionality to track 
                                an tranfers NFTs.</p>
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion sx={{ borderRadius: '5px' }}>
                        <AccordionSummary expandIcon={ <ExpandMoreIcon /> }>
                            <Typography sx={{ fontWeight: 'bold' }}>How the payment period process works</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography sx={{ textAlign: 'justify' }}>
                                <p style={{ marginTop: '0' }}>You can pay in your desired timeline. Here is an example of what a payment process would look like:</p>
                                <ul>
                                    <li>The <strong>payment interval</strong> and the number of <strong>payments</strong> to be made in total are taken into account.</li>
                                    <li>Let's imagine that a <strong>payment interval</strong> of 10 days and a number of <strong>payments</strong> of 3 are selected.</li>
                                    <li>This means that every 10 days the payment will be made until completing the 3 total <strong>payments</strong>.</li>
                                    <li>In this example, the total would be paid in 30 days.</li>
                                </ul>
                                The formula is: <strong>Days to pay = Payment Interval × Payments</strong>
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion sx={{ borderRadius: '5px' }}>
                        <AccordionSummary
                            expandIcon={ <ExpandMoreIcon /> }>
                            <Typography sx={{ fontWeight: 'bold' }}>How to create NFTs from the SBN contract</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography sx={{ textAlign: 'justify' }}>
                                <p style={{ marginTop: '0' }}>In the module called <strong>Create NFT</strong>, you can create the NFT's you want, 
                                thanks to the NFTs browser (gallery) that we designed for you. When you select an 
                                element from the gallery, the respective data will be autocompleted to the form and 
                                it will only be necessary to select the payment interval and the amount of payments 
                                to be made. The date of your last payment will be displayed and all you have to do 
                                is click on the <strong>purchase button</strong>!</p>
                                <strong>Reminder: </strong>The items in the gallery are extracted based on the actions performed in the <strong>Create Contract</strong> module.
                            </Typography>
                            <Accordion style={ accordionStyles }>
                                <AccordionSummary expandIcon={<EmojiObjectsIcon />}>
                                    <Typography>Click here to see an example</Typography>
                                </AccordionSummary>
                                <AccordionDetails style={ centerStyles }>
                                    <Typography>
                                        <img style={ imageStyles } src={ CreateNFT } alt="Create NFT example" />
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion sx={{ borderRadius: '5px' }}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}>
                            <Typography sx={{ fontWeight: 'bold' }}>How to Sell Back</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography sx={{ textAlign: 'justify' }}>
                                <p style={{ marginTop: '0' }}>You can sell us your <strong>NFT back</strong>, we will happy to buy it back from you!</p>
                                Just follow these steps:
                                <ol>
                                    <li>You have to access the module called <strong>Sell Buyback NFT</strong>.</li>
                                    <li>A form will be displayed where you have to select the <strong>contract address</strong> and the <strong>token number</strong>. (The NFT information will be shown to you.)</li>
                                    <li>Click on the <strong>YES</strong> button.</li>
                                </ol>
                            </Typography>
                            <Accordion style={ accordionStyles }>
                                <AccordionSummary expandIcon={ <EmojiObjectsIcon /> }>
                                    <Typography>Click here to see an example</Typography>
                                </AccordionSummary>
                                <AccordionDetails style={ centerStyles }>
                                    <Typography>
                                        <img style={ imageStyles } src={ SellBackNFT } alt="SellBack NFT example" />
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>
                        </AccordionDetails>
                    </Accordion>
                </CenterContainer>
            </MainContainer>
        </div>
    );
}

export default FAQ;
