import React from "react";
import styled from "@emotion/styled";
import { Link } from 'react-router-dom'

const Container = styled.div`
  display: block;
  width: auto;
  height: 100vh;
  background: linear-gradient(180deg, #20114a 0%, rgba(97, 190, 230, 0.7) 100%);
  color: #ffffff;
  text-align: center;
  justify-content: center;
  padding: 5vh 0;

`;
const Menu = styled.div`
    padding: 5vh;
    display: inline-block;

`
const MenuItem = styled(Link)`
    display: block;
    padding: 3vh;
    color: #FFFFFF;
    font-size: 18px;
    text-decoration: none;
    :hover{
        color: #908F8F;
    }

`

function MainMenu () {
    return (
        <Container>
            <h1>Navigate to</h1>
            <Menu>
                <MenuItem to='/createContract'>Create Contract</MenuItem>
                <MenuItem to='/nftGallery'>Create NFT</MenuItem>
                <MenuItem to='/sbPage'>Sell Buyback NFT</MenuItem>
            </Menu>

        </Container>
        
    )
}
export default MainMenu;