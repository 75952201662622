import { React, useEffect } from 'react';
import styled from 'styled-components';
import { Button } from '@mui/material';
import { useAuth } from '../hooks/useAuth'
import { SBNLogo } from '../images';

const MainContainer = styled.div`
  display: grid;
  height: 100vh;
  grid-template-columns: 2fr 1fr;
  background-color: #eeeeee;
`;
const LeftContainer = styled.div`
  display: block;
  width: auto;
  color: #353434;
  text-align: center;
`;
const RightContianer = styled.div`
  display: block;
  width: auto;
  background: linear-gradient(180deg, #20114a 0%, rgba(97, 190, 230, 0.7) 100%);
  color: #ffffff;
  text-align: center;
`;
const ButtonContianer = styled.div`
  display: grid;
  gap: 10px;
  width: 30%;
  margin: auto;
`;

const VideoContianer = styled.div`
  display: block;
  gap: 10px;
  width: 50%;
  margin: 2vw auto;

`;
const TextContainer = styled.div`
  margin-top: 25vh;

`;

function Login() {
  // const { authenticate } = useMoralis();
  // const navigate = useNavigate();
  // const location = useLocation();
  const { login, loginMetaMask } = useAuth();
  
  const baseUrl = process.env.REACT_APP_AWS_S3_BUCKET;

  const walletClickHandler = (provider) => () => {
    
    try {   
      provider == 'walletConnect' ? login() : loginMetaMask();
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    const redirUrl = localStorage.getItem('redirUrl');
    if (redirUrl) {
      console.log('url was ', redirUrl)
    }
    });
  return (
    <MainContainer>
      <LeftContainer>
        <TextContainer>
          <h1>Connect your Wallet</h1>
          <p>By connecting your wallet you’re agreeing to our terms of service</p>        
          <ButtonContianer>
{/*             <Button variant="contained" color="primary" size="large" sx ={{textTransform: 'none'}} onClick={walletClickHandler('walletConnect')}>
              WalletConnect
            </Button> */}
            <Button variant="contained" color="primary" size="large" sx ={{textTransform: 'none'}} onClick={walletClickHandler('metaMask')}>
              MetaMask
            </Button>
          </ButtonContianer>
          <VideoContianer>            
            <video width='100%' controls><source src={`${baseUrl}/videos/SBN+Video+Tutorial.mp4`} /></video>
            <p>How to use SBBN</p>
          </VideoContianer>
        </TextContainer>
      </LeftContainer>
      <RightContianer>
        <TextContainer>
          <img src={SBNLogo} alt='SBBN Logo'/>
          <h3>A new way to buy, sell and trade NFTs</h3>
        </TextContainer>
      </RightContianer>
    </MainContainer>
  );
}
export default Login;
