import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { Button, Select, InputLabel, MenuItem, FormControl, TextField, Collapse, Alert } from '@mui/material';
// import _metadata from '../metadata-ipfs/_metadata.json';
import Spinner from '../components/Spinner';
import Navbar from '../components/Navbar';
// import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
// import { LocalizationProvider } from '@mui/x-date-pickers';
// import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import moment from 'moment';
import { ethers } from 'ethers';
// import { abi } from '../lib/abi.js';

const MainContainer = styled.div`
  display: grid;
  height: 100vh;
  grid-template-columns: 2fr 1fr;
`;
const LeftContainer = styled.div`
  display: block;
  width: auto;
  color: #353434;
  text-align: center;
`;
const RightContianer = styled.div`
  display: block;
  width: auto;
  height: 80vh;
  margin: 10vh 0;
  border-left: solid #908f8f 3px;
  text-align: center;
  padding: 10%;
`;
const TextContainer = styled.div`
  margin-top: 10vh;
`;
const InputContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;
`;
const NftContainer = styled.div`
  margin: 10px 0;
  height: 80vh;
  overflow-y: scroll;
`;

const StyledImg = styled.img`
  margin: 10px;
  border-radius: 10px;
  &:hover {
    cursor: pointer;
  }
`;

const LoadingSpinner = styled.div`
  text-align: center;
  margin-top: 30vh;
  height: 40vh;
`;

function NFTGallery() {
  const [duration, setDuration] = useState('');
  const [payments, setPayments] = useState('');
  const [contract, setContract] = useState('');
  const [name, setName] = useState('');
  const [date, setDate] = useState(moment());
  const [endDate, setEndDate] = useState('');
  const [imageData, setImageData] = useState('');
  const [sk, setSK] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const effectCalled = useRef(false);
  const [images, setImages] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenSuccess, setIsOpenSuccess] = useState(false);
  const [dataContracts, setDataContracts] = useState([]);

  const abi = {
    finance: [
      'function setDefaultSBNContraWpr(address _address) public',
      'function getDefaultSBNContract() public view returns (address)',
      'function initializeSBN(string memory _contractType, string memory _sbnName, address _teamToken, address _teamNFT, uint256 _maxPermitted, uint256 _price) public',
      'function getContractFactoryType() public view returns(address[])',
      'function setAccount(address _accountToPay, address _usdAddress, uint _creationConst) public',
    ],
    defaultSBN: [
      'function name() public view returns (string memory)',
      'function owner() public view returns (address)',
      'function changeOwner(address _newOwner) public',
      'function purchaseNFT(uint256 _daysInterval, uint _daysCommited, string memory uri) public payable',
      'function setPriceNFT(uint256 value) public',
      'function getPriceNFT() public view returns (uint256)',
    ],
    teamToken: ['function sbn_registerFactory(address _factory) public onlyOwner', 'function sbnFactory() public view'],
    simpleBNFT: ['function sbn_registerFactory(address _factory) public onlyOwner', 'function sbnFactory() public view'],
    usd: [
      'function approve(address spender, uint256 amount) public returns (bool)',
      'function balanceOf(address account) public returns (uint256)',
      'function allowance(address owner, address spender) public returns (uint256)',
      'function transferFrom(address sender, address recipient, uint256 amount) public returns (bool)',
      'function mint(address _account, uint256 _amount) public',
    ],
  };

  const fetchContracts = async () => {
    try {
      const provider = await new ethers.providers.Web3Provider(window.ethereum);
      const account = await window.ethereum.request({ method: 'eth_requestAccounts' });
      const signer = provider.getSigner();
      const FinanceSBN = await new ethers.Contract(process.env.REACT_APP_FinanceSBN, abi.finance, signer);
      const contracts = await FinanceSBN.getContractFactoryType();
      // const totalOfContracts = contracts.length;
      const getNames = contracts.map(async (address) => {
        const DefaultSBNC = await new ethers.Contract(address, abi.defaultSBN, signer);
        const name = await DefaultSBNC.name();
        return { name: name, contractAddress: address };
      });

      const dataContracts = await Promise.all(getNames);
      setDataContracts(dataContracts);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    const fetchCall = async () => {
      try {
        setIsLoading(true);
        await fetchContracts();
      } catch (error) {
        console.error(error);
        setIsOpen(true);
      } finally {
        setIsLoading(false);
      }
    };
    if (!effectCalled.current) {
      fetchCall();
      effectCalled.current = true;
    }
  }, []);

  useEffect(() => {
    const fetchCall = async () => {
      try {
        // debugger;
        setIsLoading(true);
        const baseUrl = process.env.REACT_APP_AWS_API;
        if (contract) {
          const response = await fetch(`${baseUrl}/candidates/contract/${contract.toLowerCase()}`); //aqui se va a modificar la ruta para acceder a ligthouse
          const candidatesData = await response.json();
          console.log('candidatesData---- :', candidatesData[0]);
          setImages(candidatesData);
        }
      } catch (error) {
        console.error(error);
        setIsOpen(true);
      } finally {
        setIsLoading(false);
      }
    };

    fetchCall();
  }, [contract]);

  function makeDays() {
    const days = [];
    for (var i = 60; i < 90; i++) {
      const obj = {};
      obj['value'] = (i + 1) * 86400; //86400 = seconds in a day
      obj['id'] = i + 1;
      obj['text'] = (i + 1).toString() + ' days';
      days.push(obj);
    }
    return days;
  }

  const handleDuration = (e) => {
    setDuration(e.target.value);

    if (payments !== '') {
      const days = e.target.value / 86400;
      setEndDate(days * payments);
    }
  };

  const handleContract = (e) => {
    // debugger;
    setContract(e.target.value);
    console.log('contract-selected:', e.target.value);
  };

  const handlePayments = (e) => {
    setPayments(e.target.value);
    const days = duration / 86400;
    setEndDate(days * e.target.value);
  };

  const toDecimal = (num) => {
    return num.toFixed(6) * 10 ** 6;
  };

  const handleCreateNFT = async () => {
    try {
      const provider = await new ethers.providers.Web3Provider(window.ethereum);
      const account = await window.ethereum.request({ method: 'eth_requestAccounts' });
      const signer = provider.getSigner();
      const tokenURI = JSON.stringify(imageData);
      const timeInterval = duration;
      const totalPayments = payments;
      const DefaultSBNC = await new ethers.Contract(contract, abi.defaultSBN, signer);
      const USD = await new ethers.Contract(process.env.REACT_APP_USDC, abi.usd, signer);
      const cost = parseInt(await DefaultSBNC.getPriceNFT(), 16);
      console.log('aprove :', await (await USD.approve(DefaultSBNC.address, toDecimal(cost))).wait());
      // console.log(await (await DefaultSBNC.purchaseNFT(timeInterval, timeInterval * totalPayments, tokenURI, { value: ethers.utils.parseEther('0.00000000000001') })).wait());
      console.log(await (await DefaultSBNC.purchaseNFT(timeInterval, timeInterval * totalPayments, tokenURI)).wait());
      console.log('purchase NFT terminado');
      const baseUrl = process.env.REACT_APP_AWS_API;

      const response = await fetch(`${baseUrl}/candidates`, {
        method: 'PUT',
        body: JSON.stringify({
          SK: sk,
          minted: true,
        }),
      });

      console.log('proceso completado');

      clearInfo();
    } catch (error) {
      console.error(error);
    }
  };

  const purchasedClickHandler = async (e) => {
    try {
      setIsLoading(true);
      const currentContract = contract;
      setContract('');
      // await handleGetApproveWithdrawNFT();
      await handleCreateNFT();
      setContract(currentContract);
    } catch (error) {
      console.error(error);
      setIsOpen(true);
    } finally {
      setIsLoading(false);
    }
  };

  const nftClickHandler = (e) => {
    setName(e.metadataIPFS.name);
    setImageData(e.metadataIPFS);
    setSK(e.SK);
  };

  const clearInfo = (e) => {
    setName('');
    setPayments('');
    setDuration('');
  };

  const dateHandler = (e) => {
    setDate(e);
  };

  const days = makeDays();

  return isLoading ? (
    <LoadingSpinner>
      <Spinner>{/*hacer bonito el spinner y no dejar de renderear lo otro */}</Spinner>
    </LoadingSpinner>
  ) : (
    <div>
      <Navbar />
      <MainContainer id="page-wrap">
        <LeftContainer>
          <TextContainer>
            <h2>Gallery / NFT Browser</h2>
            <FormControl sx={{ m: 1, minWidth: 350 }}>
              <InputLabel id="Contract-label" color="secondary">
                Contract
              </InputLabel>
              <Select labelId="contract-label" id="contract" value={contract} label="Contract" onChange={handleContract}>
                {dataContracts.map((item, index) => (
                  <MenuItem key={index} value={item.contractAddress}>
                    {item.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <NftContainer>
              <div>
                {images.map((item) => (
                  // <img key={item.id} style={{ marginRight: '10px', marginBottom: '10px' }} src={item.url} alt={item.id} />
                  <StyledImg key={item.imageName} src={item.imageURL} alt={item.imageName} onClick={() => nftClickHandler(item)} />
                ))}
              </div>
            </NftContainer>
          </TextContainer>
        </LeftContainer>
        <RightContianer>
          <h2> New NFT</h2>

          <Collapse in={isOpenSuccess}>
            <Alert
              severity="success"
              action={
                <Button
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={() => {
                    setIsOpenSuccess(false);
                  }}
                >
                  close
                </Button>
              }
            >
              Success, your NFT was minted correctly.
            </Alert>
          </Collapse>

          <Collapse in={isOpen}>
            <Alert
              severity="error"
              action={
                <Button
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={() => {
                    setIsOpen(false);
                  }}
                >
                  close
                </Button>
              }
            >
              Oops! Something went wrong, please try again.
            </Alert>
          </Collapse>
          <InputContainer>
            <div style={{ gridArea: '1 / 1 / 2 / 2' }}>
              <FormControl fullWidth>
                <InputLabel id="payment-interval" color="secondary">
                  Payment Interval
                </InputLabel>
                <Select labelId="payment-interval" id="parment-interval" value={duration} label="Payment Interval" onChange={handleDuration} MenuProps={{ style: { maxHeight: 350 } }}>
                  {days.map((item) => (
                    <MenuItem key={item.id} value={item.value}>
                      {item.text}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
            <div style={{ gridArea: '1 / 2 / 2 / 3' }}>
              <FormControl fullWidth>
                <InputLabel id="duration-label" color="secondary">
                  Payments
                </InputLabel>
                <Select labelId="duration-label" id="duration" value={payments} label="Payments" onChange={handlePayments}>
                  <MenuItem value={5}>5</MenuItem>
                  <MenuItem value={7}>7</MenuItem>
                  <MenuItem value={9}>9</MenuItem>
                </Select>
              </FormControl>
            </div>
            <div style={{ gridArea: '2 / 1 / 3 / 3' }}>
              {/*<TextField id="date" value={name} color="secondary" variant="outlined" placeholder="Start Date" fullWidth={true} InputProps={{ readOnly: true }} /> */}
              {/* <LocalizationProvider dateAdapter={AdapterMoment}>
                <DesktopDatePicker label="Start Date" inputFormat="DD/MM/YYYY" value={date} onChange={dateHandler} minDate={moment()} renderInput={(params) => <TextField {...params} fullWidth />} />
              </LocalizationProvider> */}
            </div>
            <div style={{ gridArea: '3 / 1 / 5 / 3' }}>
              <TextField id="name" value={name} color="secondary" variant="outlined" placeholder="Name" fullWidth={true} InputProps={{ readOnly: true }} />
            </div>
            <div
              style={{
                gridArea: '6 / 1 / 6 / 3',
                width: '80%',
                margin: '0 auto',
              }}
            >
              <h4>Your last payment will be on {date.clone().add(endDate, 'days').format('ll')}</h4>
              <Button variant="contained" color="primary" fullWidth={true} onClick={purchasedClickHandler}>
                Purchase
              </Button>
            </div>
          </InputContainer>
        </RightContianer>
      </MainContainer>
    </div>
  );
}
export default NFTGallery;
