import React from 'react';
import styled from '@emotion/styled';
import Navbar from '../components/Navbar';
import { SBNFlowchart } from '../images/index';

const MainContainer = styled.div`
  height: 100vh;
`;
const CenterContainer = styled.div`
  display: block;
  width: 65vw;
  margin: auto;
  padding-top: 10vh;
  text-align: justify;
`;

const DiagramContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
`;

const imageStyles = {
    maxWidth: '100%',
    height: 'auto',
    padding: '0',
    margin: '0',
}

function Info() {

    return (
        <div>
            <Navbar />
            <MainContainer>
                <CenterContainer>
                    <h1 style={{ textAlign: 'center' }}>INFO</h1>
                    <p>Have you purchased an <strong>SBBN NFT?</strong></p>
                    <p>
                        Congratulations! This NFT that you have purchased is different than your regular NFTs <strong>where we can buy it
                        back from you at the original price it was sold</strong>.
                    </p>
                    <p>
                        You just have to make sure you can make the payments due in case there are payments due coming
                        from the original buyer of this NFT.
                    </p>
                    <p>
                        By the originating contract of this NFT the NFT was set to be purchased in a number of payments
                        where the last payment is optional and <strong>at this time it can be sold back us (SBBN)</strong>.
                    </p>
                    <p>View diagram for a more visual explanation of the above.</p>
                    <DiagramContainer>
                        <img style={ imageStyles } src={ SBNFlowchart } alt="SBNN Flowchart" />
                    </DiagramContainer>
                </CenterContainer>
            </MainContainer>
        </div>
    );
}

export default Info;
