import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
// import App from './App';
import Login from './Pages/Login';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter, Routes, Route, Link } from 'react-router-dom';
import EmailCapture from './Pages/EmailCapture';
import NFTGallery from './Pages/NFTGallery';
import LoadImages from './Pages/LoadImages';
import { createTheme, CssBaseline, ThemeProvider } from '@mui/material';
import MainMenu from './Pages/MainMenu';
import CreateContract from './Pages/CreateContract';
import SBPage from './Pages/SBPage';
import FAQ from './Pages/FAQ';
import Info from './Pages/Info';
import { AuthProvider } from './hooks/useAuth';
import { ProtectedRoute } from './components/ProtectedRoute';
import { ProtectedLogin } from './components/ProtectedLogin'; 

const theme = createTheme({
  palette: {
    type: 'light',
    primary: {
      main: '#20114A',
    },
    secondary: {
      main: '#61BEE5',
    },
    background: {
      default: '#EEEEEE',
    },
    text: {
      primary: '#353434',
      secondary: '#908F8F',
    },
    divider: '#61BEE5',
  },
});

const appId = process.env.REACT_APP_APP_ID;
const serverUrl = process.env.REACT_APP_SERVER_URL;

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
      <BrowserRouter>
        <ThemeProvider theme={theme}>
          <CssBaseline>
            <AuthProvider>
              <Routes>
                <Route element={<ProtectedLogin />}>
                  <Route path="/" element={<Login />} />
                  <Route path="/login" element={<Login />} />
                </Route>
                <Route element={<ProtectedRoute />}>
                  <Route path="/menu" element={<MainMenu />} />
                  <Route path="/nftGallery" element={<NFTGallery />} />
                  <Route path="/emailCapture" element={<EmailCapture />} />
                  <Route path="/createContract" element={<CreateContract />} />
                  <Route path="/LoadImages" element={<LoadImages />} />
                  <Route path="/sbPage" element={<SBPage />} />
                  <Route path="/info" element={<Info />} />
                  <Route path="/faq" element={ <FAQ /> }/>
                </Route>
                <Route
                  path="*"
                  element={
                    <main style={{ padding: '1rem' }}>
                      <p>There's nothing here!</p>
                      <Link to='/login'>Back to login</Link>
                    </main>
                  }
                />
                {/* <App /> */}
              </Routes>
              </AuthProvider>
          </CssBaseline>
        </ThemeProvider>
      </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
